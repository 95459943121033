import React from 'react';
import { Gallery } from "react-grid-gallery";
import Modal from "./Modal";

interface ShowGalleryState {
  data: any; // Parempi olisi määritellä tarkempi tyyppi, jos mahdollista
  isLoaded: boolean;
  error?: Error;
  showModal: boolean;
  selectedImageSrc?: string;
}

class ShowGallery extends React.Component<{}, ShowGalleryState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      data: null,
      isLoaded: false,
      showModal: false,
      selectedImageSrc: undefined,
    };
    this.handleImageClick = this.handleImageClick.bind(this);
  }

  componentDidMount() {
    fetch(
      'https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=db1016c28c842dd218f046b248e9b55f&photoset_id=72157715569139551&format=json&nojsoncallback=1',
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          isLoaded: true,
          data,
        })
      )
      .catch((error) => {
        console.error('Error fetching data: ', error);
        this.setState({
          isLoaded: true,
          error,
        });
      });
  }


  handleImageClick = (index) => {
    const { photoset } = this.state.data;
    const photo = photoset.photo[index];
    const selectedImageSrc = `https://farm${photo.farm}.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_b.jpg`;
    this.setState({ showModal: true, selectedImageSrc });
  };



  closeModal = () => {
    this.setState({
      showModal: false,
      selectedImageSrc: undefined,
    });
  }

  render() {
    const { data, isLoaded, showModal, selectedImageSrc } = this.state;

    let pictures = [];
    if (isLoaded && data && data.photoset) {
      pictures = data.photoset.photo.map((photo, index) => ({
        src: `https://farm${photo.farm}.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_q.jpg`,
        thumbnail: `https://farm${photo.farm}.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_q.jpg`,
        thumbnailWidth: 240,
        thumbnailHeight: 240,
        width: 240,
        height: 240,
        isSelected: false,
        caption: photo.title,
        // Tärkeä osa: Käytä customOverlay propia määrittääksesi klikkauskäsittelijän
        customOverlay: (
          <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: "green", opacity: 0.5 }} onClick={() => this.handleImageClick(index)}>
          </div>
        ),
      }));
    }

    return (
      <div>
        {isLoaded ? (
          <>
            <Gallery images={pictures} enableImageSelection={false} onClick={this.handleImageClick} />
            {showModal && selectedImageSrc && (
              <Modal
                show={showModal}
                onCloseButtonClick={this.closeModal}
                selectedImageSrc={selectedImageSrc}
              />
            )}
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}

export default ShowGallery;
