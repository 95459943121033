import React from 'react';
import pricingData from '../data/pricingData.json';
import '../App.css';

const Pricing = () => {
  return (
    <div className="pricing">
      <h1>{pricingData.title}</h1>
      {pricingData.categories.map((category, index) => (
        <div key={index} className="pricing-category">
          <h2>{category.title}</h2>
          <p>{category.description}</p>
          {category.price && <p><strong>Hinta:</strong> {category.price}</p>}
          {category.additional && <p>{category.additional}</p>}
          {category.pricing && (
            <ul>
              {category.pricing.map((item, idx) => (
                <li key={idx}>{item.task}: {item.price}</li>
              ))}
            </ul>
          )}
          {category.contact && <p>{category.contact}</p>}
        </div>
      ))}
    </div>
  );
};

export default Pricing;
