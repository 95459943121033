import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import logo from '../assets/lennart_logo.png';
import Favicon from "react-favicon";



const Header = () => {
  const [faviconUrl, setFaviconUrl] = useState(
    "https://takanen.eu/favicon.ico"
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
    <Favicon url={faviconUrl} />
    <title>takanenphotography.com</title>
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
        <nav className={`nav ${isOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={toggleMenu}>Etusivu</Link></li>
            <li><Link to="/pricing" onClick={toggleMenu}>Hinnasto</Link></li>
              <li><Link to="/photo-orders" onClick={toggleMenu}>Kuvatilaukset</Link></li>
            <li><Link to="/photographer" onClick={toggleMenu}>Valokuvaaja</Link></li>
            <li><Link to="/portfolio" onClick={toggleMenu}>Portfolio</Link></li>
            <li><Link to="https://blog.takanen.eu/" onClick={toggleMenu}>Blogi</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>Yhteydenottopyyntö</Link></li>
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>
    </div>
  );
};

export default Header;
