import React from 'react';
import '../App.css';
import photographerImage from '../assets/photographer.jpg';

const Photographer = () => {
  return (
    <div className="photographer">
      <h1>Minusta</h1>
      <div className="photographer-content">
        <img src={photographerImage} alt="Photographer" className="photographer-image" />
        <div className="photographer-text">
          <p>
            Minulla on ollut ilo työskennellä vuosina 1998-2001 huippumainostoimistoissa, kuten TBWA:ssa, yhdessä alan arvostettujen ammattilaisten, kuten Paltenmaan, Huttusen ja Santalan kanssa. Tämän ajanjakson aikana sain mahdollisuuden tutustua fantastisiin ihmisiin ja ammentaa heidän kokemuksestaan ja osaamisestaan. Tämä kokemus toimi vankkana perustana uralleni suunnittelijana ja valokuvaajana.
          </p>
          <p>
            Vuodesta 2002 vuoteen 2012 toimin omassa yrityksessäni suunnittelijana, ohjelmoijana ja valokuvaajana. Tämä ajanjakso oli merkittävä oppimisprosessi, jossa kehityin monipuoliseksi osaajaksi ja opin hallitsemaan erilaisia projekteja alusta loppuun. Vuonna 2012 tapahtui kuitenkin käännekohta, kun hankin itselleni Canon 5D Mark II -kameran.
          </p>
          <p>
            Canon 5D Mark II avasi minulle uusia ovia valokuvauksen maailmaan. Aloin kuvata intohimoisesti ja huomasin, että tämä kamera oli täydellinen väline luovuudelleni. Vuosien varrella olen ollut mukana monissa suurissa projekteissa, toimien niin video- ja kamera-assistenttina kuin kuvaajanakin. Olen kiitollinen kaikille ihmisille, jotka ovat antaneet minulle mahdollisuuden oppia, tuottaa ja rakentaa erityyppisiä kuvauksia, kuten ohjelma-, muoto-, tuotanto- ja henkilökuvauksia.
          </p>
          <p>
            Tällä hetkellä työskentelen edelleen valokuvaajana ja suunnittelijana, keskittyen erityisesti tarjoamaan laadukkaita ja persoonallisia valokuvauspalveluita asiakkaille. Tavoitteeni on aina ylittää asiakkaideni odotukset ja tuottaa visuaalisesti vaikuttavia ja teknisesti laadukkaita kuvia.
          </p>
          <p>
            Kiitän kaikkia, jotka ovat olleet osa matkaani ja auttaneet minua kasvamaan ja kehittymään ammattilaisena. Jatkan intohimoisesti luovuuden ja teknologian yhdistämistä, ja odotan innolla uusia haasteita ja mahdollisuuksia tulevaisuudessa.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Photographer;
