import React, { useEffect } from 'react';
import './Modal.css'; // Tuo CSS

interface ModalProps {
  show: boolean;
  onCloseButtonClick: () => void;
  selectedImageSrc?: string; // Optional property
}

const Modal: React.FC<ModalProps> = ({ show, onCloseButtonClick, selectedImageSrc }) => {
  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCloseButtonClick();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [onCloseButtonClick]);

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onCloseButtonClick();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-wrapper" onClick={handleOverlayClick}>
      <div className="modal">
        <img src={selectedImageSrc} alt="Modal Content" />
        <button onClick={onCloseButtonClick}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
