import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../App.css';

const Contact = () => {
  const form = useRef();
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const email = form.current.user_email.value;
    const sentEmails = JSON.parse(localStorage.getItem('sentEmails')) || {};

    if (sentEmails[email] && sentEmails[email] >= 2) {
      setMessage('Hups, olemme jo lähettäneet sinulle kaksi viestiä.');
      return;
    }

    if (window.location.hostname === 'localhost') {
      // Simulate successful send on localhost
      handleSuccess(email, sentEmails);
    } else {
      emailjs.sendForm('service_lxqtp95', 'template_4310luv', form.current, 'c84N-MTKDQvgRIliN')
        .then((result) => {
          console.log(result.text);
          handleSuccess(email, sentEmails);
        }, (error) => {
          console.log(error.text);
          setMessage('Hups, jotain meni pieleen. Yritä uudelleen.');
        });
    }
  };

  const handleSuccess = (email, sentEmails) => {
    sentEmails[email] = sentEmails[email] ? sentEmails[email] + 1 : 1;
    localStorage.setItem('sentEmails', JSON.stringify(sentEmails));
    setMessage('Kiitos yhteydenotosta! Olemme vastaanottaneet viestisi.');
    setFormSubmitted(true);
  };

  return (
    <div className="contact">
      <h1>Yhteystiedot</h1>
      {formSubmitted ? (
        <p className="contact-message">{message}</p>
      ) : (
        <form ref={form} onSubmit={sendEmail} className="contact-form">
          <label>Nimi</label>
          <input type="text" name="user_name" required />
          <label>Sähköposti</label>
          <input type="email" name="user_email" required />
          <label>Viesti</label>
          <textarea name="message" required />
          <input type="submit" value="Lähetä" />
        </form>
      )}
      {message && !formSubmitted && <p className="contact-message">{message}</p>}
    </div>
  );
};

export default Contact;
