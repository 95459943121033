import React from 'react';
import ImageCarousel from '../components/ImageCarousel';
import image0 from '../assets/imagecarousel/kuva0.jpg';
import image1 from '../assets/imagecarousel/kuva1.jpg';
import image2 from '../assets/imagecarousel/kuva1.jpg';
import logo from '../assets/lennart_logo.png';

import '../App.css';

const Home = () => {
  const images = [
    { src: image0, alt: 'Kuva 0', legend: '' },
    { src: image1, alt: 'Kuva 1', legend: 'inspirate' },
    { src: image2, alt: 'Kuva 3', legend: 'creative' },
  ];

  return (
    <div className="home">
      <h1>Tervetuloa Lennart Takasen Portfolioon</h1>
      <p>Olen ohjelmoija, valokuvaaja ja suunnittelija. Tutustu töihini ja palveluihini.</p>
      <ImageCarousel images={images} />
    </div>
  );
};

export default Home;
