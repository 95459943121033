import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import '../App.css';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <p>&copy; 2024 Lennart Takanen. All rights reserved.</p>
      </footer>
      <FloatingWhatsApp
        phoneNumber="+358407082294"
        accountName="Lennart Takanen"
        avatar="https://scontent-hel3-1.xx.fbcdn.net/v/t39.30808-6/400613725_10161314982128522_5185178138942623711_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=ZYa8yTx5os8Q7kNvgFWbouB&_nc_ht=scontent-hel3-1.xx&oh=00_AYDGVURp81a7ucn8erb8wy29HNUKcL6tFvRj9rjbaVTArA&oe=6680928A"
        chatMessage="Miten voin auttaa?"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </div>
    );
};

export default Footer;


