import React from 'react';

const PhotoOrders = () => {
  return (
    <div className="photo-orders">
      <h1>Kuvatilaukset</h1>
      <p>Tulossa.</p>
    </div>
  );
};

export default PhotoOrders;
