import React from 'react';
import ShowGallery from '../components/Gallery';

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h1>Portfolio</h1>
      <p>Tutustu töihini täällä.</p>
      <ShowGallery />
    </div>
  );
};

export default Portfolio;
